import React from 'react';
import { connect } from 'react-redux';
import { get, debounce, pick } from 'lodash';
import MobileDetect from 'mobile-detect';
import EventEmitter from 'events';
import NoSleep from 'nosleep.js';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import SendIcon from '@material-ui/icons/Send';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import HiddenContent from '../components/hidden-content';
import ClinicLogo from '../components/clinic-logo';
import Page from './page';
import { submitPRO } from '../state/pro-forms';
import { updateAppointment } from '../state/appointments';
import createPeer, { startVideo, startRearVideo } from '../lib/createAnswer';
import {
  messageClinic,
  socketEvents,
  VITAL_CORE_MSGS_IN,
  VITAL_CORE_MSGS_OUT,
} from '../websocket';
import config from '../config';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { colors, fontSizing } from '../lib/styles';
import { throttledReset } from '../initializers/activity';
import i18nTranslator from '../lib/i18next';
import { ClockWithError, NoInternet } from '../lib/icons';
import startVitalsRunner, {
  addCallbacksToWebWorker,
  isWebWorkerReady,
  loadDetector,
  preparationVideo,
  resetVitalsRunnerOnUnmount,
  setGuideBoxColor,
  stopPreparationVideo,
  startSessionJS,
  killWebWorker,
} from '../lib/vitals-runner-rr';
import { vcDataStatusMessageMap, vcProcessDataStatus } from '../lib/vital-core';
import IvcTelemedicineResults from '../components/ivc-telemedicine-results';
import IvcTelemedicineTimeout from '../components/ivc-telemedicine-timeout';

const IVC_STATE = {
  ready: 'ready',
  measuring: 'measuring',
  analyzing: 'analyzing',
  showResults: 'show_results',
  timeout: 'timeout',
};

const processingTimeoutLength = 300000;

const styles = {
  fab: {
    margin: '0px',
    top: '20px',
    right: '20px',
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  heartIcon: {
    color: colors.healthyRed,
    fontSize: '25px',
  },
  // logo: {
  //   height: '200px',
  //   paddingTop: '100px',
  //   width: '200px',
  // },
  divider: {
    margin: '30px 0px',
  },
  providerVideo: {
    minHeight: '450px',
    height: '100%',
    maxHeight: '100vh',
    maxWidth: '100%',
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    position: 'fixed',
  },
  waitingView: {
    height: '450px',
    maxHeight: '100vh',
    maxWidth: '80%',
    margin: '0px auto 10rem',
    textAlign: 'center',
    width: '100%',
  },
  patientVideo: {
    height: '90px',
    objectFit: 'cover',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '75px',
    zIndex: 1000,
  },
  patientVideoUser: {
    transform: 'scaleX(-1)',
  },
  patientVideoEnvironment: {
    transform: 'scaleX(1)',
  },
  readingRate: {
    fontSize: '2em',
    padding: '5px',
    textAlign: 'center',
  },
  heart: {
    marginLeft: '5px',
    position: 'relative',
    top: '-34px',
  },
  heartRate: {
    flex: 1,
    fontSize: '1em',
    margin: '0px 20px',
    width: '20%',
  },
  disabledVital: {
    flex: 1,
    color: '#9D9D9D',
    fontSize: '1em',
    margin: '0px 20px',
    width: '20%',
  },
  vitals: {
    display: 'flex',
    color: 'red',
    height: '25vh',
    fontSize: '1.75em',
    margin: '20px auto',
    maxWidth: '600px',
    padding: '0px 10px',
    alignItems: 'space-between',
    justifyContent: 'center',
  },
  vitalsLoadingIndicator: {
    fontSize: '24px',
    height: '10px',
    marginTop: '-20px',
  },
  breath: {
    position: 'relative',
    top: '-63px',
  },
  breathRate: {
    flex: 1,
    fontSize: '1em',
    marginRight: '20px',
    color: 'blue',
    width: '20%',
  },
  container: {
    backgroundColor: colors.white,
    fontSize: fontSizing.body,
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  topSection: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  progressBad: {
    color: 'red',
    transform: 'none',
  },
  progressGood: {
    color: 'green',
  },
  progressNone: {
    color: 'grey',
  },
  vitalsDescription: {
    fontSize: '.5em',
  },
  patientCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  videoContainer: {
    flexGrow: 1,
    margin: '0px auto',
    height: '100vh',
    maxWidth: '540px',
    width: '100%',
  },
  waitingInstructions: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  message: {
    marginBottom: '15px',
  },
  messageBox: {
    display: 'flex',
  },
  chat: {
    position: 'sticky',
    bottom: 0,
    margin: 0,
    padding: 0,
    width: '100%',
  },
  messageSection: {
    backgroundColor: colors.white,
    minHeight: 'inherit',
    borderTop: `8px solid ${colors.primaryColor}`,
  },
  messageDisplayBox: {
    padding: 10,
    overflow: 'hidden',
    overflowY: 'scroll',
    textAlign: 'left',
    minHeight: '25vh',
    height: '100%',
    maxHeight: '25vh',
  },
  messageField: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderRadius: 30,
    backgroundColor: '#eaeaea',
  },
  bottomNavMenu: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  bottomNavIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: 60,
    backgroundColor: '#fff',
    borderRadius: '100%',
    color: 'black',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:active': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '& *': {
      fontSize: 35,
    },
  },
  bottomNavOffIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: 60,
    backgroundColor: colors.errorRed,
    borderRadius: '100%',
    fontSize: 35,
    color: '#fff',
    '&:hover': {
      backgroundColor: colors.errorRed,
    },
    '&:active': {
      backgroundColor: colors.errorRed,
    },
    '&:focus': {
      backgroundColor: colors.errorRed,
    },
    '& *': {
      fontSize: 35,
    },
  },
  unreadMessages: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: 60,
    backgroundColor: colors.primaryColor,
    color: colors.white,
    borderRadius: '100%',
    '& *': {
      fontSize: 35,
    },

  },
  closeChat: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    marginLeft: 'auto',
    borderRadius: '10px',
    backgroundColor: colors.primaryColor,
    color: colors.white,
    margin: 10,
  },
  letInProviderButton: {
    bottom: 150,
    fontSize: fontSizing.small,
    left: '50%',
    maxWidth: 525,
    position: 'absolute',
    transform: 'translateX(-50%)',
    width: '80vw',
  },
  patientCanvasVisible: {
    display: 'none',
    minHeight: '450px',
    height: '100%',
    maxHeight: '100vh',
    maxWidth: '100%',
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    position: 'fixed',
    zIndex: 2,
  },
  ivcContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 3,
  },
  ivcButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    maxWidth: 450,
    padding: 10,
    '&:disabled': {
      background: '#d9d8d8',
    },
  },
  ivcButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  },
  loadingModel: {
    fontSize: fontSizing.body,
    position: 'relative',
  },
  linearProgress: {
    background: 'lightgrey',
    height: 40,
  },
  linearProgressText: {
    color: 'white',
    left: 10,
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    zIndex: 2,
  },
  ivcTelemedicineResults: {
    background: colors.white,
    padding: 20,
  },
  ivcHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 4,
  },
  vitalCoreErrorContainer: {
    background: colors.errorRed,
    color: 'white',
    fontSize: fontSizing.body,
    padding: 10,
  },
  vitalCoreErrorText: {
    textAlign: 'center',
  },
  vitalCoreErrorRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapperSmall: {
    display: 'inline-block',
    marginRight: 10,
    width: 25,
    '& path': {
      fill: 'white',
    },
  },
  processingSection: {
    backgroundColor: colors.questionBackground,
    fontSize: fontSizing.body,
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 60,
    textAlign: 'center',
  },
  circularProgressContainer: {
    alignItems: 'center',
    backgroundColor: colors.questionBackground,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    position: 'relative',
  },
  circularProgressText: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  circularProgressTextPercent: {
    fontSize: fontSizing.smallXX,
  },
  circularProgressWrapper: {
    display: 'inline-flex',
  },
};

class VideoCallPro2 extends Page {
  constructor(props) {
    super(props);
    this.sound = new Audio();
    this.sound.src = '/sound/provider_in_session.mp3';
    this.state = {
      providerHangup: props.hangupReload || false,
      providerReady: false,
      providerSharingScreen: false,
      patientVideoClass: props.hangupReload ? 'patientVideo' : 'providerVideo', // hack to fix a safari problem
      cameraState: '',
      cameraError: '',
      includeVitals: false,
      previousStreamHeight: 0,
      me: {
        events: new EventEmitter(),
        candidates: [],
        setDescription: false,
        setShowPoorConnectionMessage: this.setShowPoorConnectionMessage,
        videoTrack: {
          enabled: true,
        },
        audioTrack: {
          enabled: true,
        },
      },
      cameraMode: 'user',
      kffer: null,
      signalPercent: 0,
      connecting: false,
      workerPct: 0,
      md: null,
      soundBool: false,
      showPoorConnectionMessage: false,
      showChat: false,
      message: '',
      messageHx: [],
      viewedAll: true,
      clinic_is_typing: false,
      micOn: true,
      videoOn: true,
      askVideo: false,
      delayedStream: null,
      inIvcMode: false,
      patientCanvasVisibleHeight: null,
      patientCanvasVisibleWidth: null,
      timeLeft: 0,
      percentLeft: 1,
      ivcSignsMsgs: [],
      workerReady: false,
      coreWarnings: [],
      ivcCriticalErrorCount: 0,
      ivcSessionCount: 0,
      ivcShouldRestart: null,
      ivcShouldReload: null,
      ivcVitalsMeasurements: [],
      ivcErrorCode: null,
      ivcState: IVC_STATE.ready,
      ivcTimeoutData: {},
      licensedVitals: {
        BP: false,
        HR: false,
        BR: false,
        SPO2: false,
      },
    };

    this.docVideo = React.createRef();
    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
    this.messagesEndRef = React.createRef();
    this.timer = React.createRef();
    this.patientCanvasVisible = React.createRef();

    this.noSleep = null;
    this.processingTimer = null;
    this.moduleDownloadingTimer = null;
    this.startWorkerRAF = null;
  }

  scrollToBottom = () => {
    const scroll = this.messagesEndRef.current && this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    return scroll;
  }

  handleProviderSound = (providerReady) => {
    if (providerReady === true && !this.state.soundBool) {
      // this.sound.play();
      this.setState({ soundBool: true });
    }
  };

  componentDidMount = async () => {
    const md = new MobileDetect(window.navigator.userAgent);
    this.setState({ md });
    this.startCamera();
    const { userId, appointmentId, clinicId } = this.props;
    apiFetch(`/users/${userId}/appointments/${appointmentId}/messages`, { method: 'GET' })
      .then((data) => {
        const messages = data.map((msg) => { return { from: msg.user_id, message: msg.message, created_at: msg.created_at }; });
        const sorted = messages.sort((msgA, msgB) => ((msgA.created_at > msgB.created_at) ? 1 : -1));
        this.setState({ messageHx: sorted });
      }).catch(() => this.setState({ messageHx: [] }));

    socketEvents.once('END_SESSION', this.handleComplete);
    socketEvents.on('PROVIDER_HANGUP', this.handleProviderHangup);
    this.heartbeatInterval = setInterval(() => {
      messageClinic(clinicId, { type: 'PATIENT_HEARTBEAT', hello: 'darkness my old friend' });
    }, 3000);
    socketEvents.on('CLINIC-IS-TYPING', this.clinicIsTypingEventListener);
    socketEvents.on('CLINIC-IS-NOT-TYPING', this.clinicIsNotTypingEventListener);
    socketEvents.on('CLINIC-SMS', this.clinicSMSEventListener);
    socketEvents.on(VITAL_CORE_MSGS_IN.enableVitalCore, this.startPreparationVideo);

    this.activityTimer = setInterval(throttledReset, 5000);
  }

  componentWillUnmount = () => {
    const { clinicId } = this.props;

    if (this.noSleep) this.noSleep.disable();

    messageClinic(clinicId, { type: 'PATIENT_HEARTBEAT_STOP' });
    this.endCall();
    stopPreparationVideo();
  }

  clinicIsTypingEventListener = () => {
    if (this.state.clinic_is_typing) return;

    this.setState({ clinic_is_typing: true });
    this.scrollToBottom();
  }

  clinicIsNotTypingEventListener = () => {
    if (!this.state.clinic_is_typing) return;

    this.setState({ clinic_is_typing: false });
    this.scrollToBottom();
  }

  clinicSMSEventListener = (data) => {
    const { messageHx, showChat } = this.state;
    const newMessageHx = [...messageHx, { from: data.from, message: data.message }];
    this.setState({ messageHx: newMessageHx });
    if (!showChat) this.setState({ viewedAll: false });
    this.scrollToBottom();
  }

  setShowPoorConnectionMessage = (showMessage) => {
    this.setState({ showPoorConnectionMessage: Boolean(showMessage) });
  }

  handleProviderHangup = () => {
    if (this.state.me.peer) this.state.me.peer.close();
    this.setState({ providerHangup: true, providerReady: false, soundBool: false });

    if (this.state.md && this.state.md.mobile() && this.state.md.userAgent() === 'Chrome') {
      const { hangupReload, location } = this.props;
      if (hangupReload) {
        window.location.reload(false);
      } else if (Object.keys(location.query).length) {
        window.location.href = document.URL + '&hangupReload=true';
      } else {
        window.location.href = document.URL + '?hangupReload=true';
      }
    }
  }

  endCall = () => {
    try {
      clearInterval(this.heartbeatInterval);
      try {
        this.state.me.audioTrack.stop();
      } catch (e) {
        console.error('error stopping me audio track');
      }
      try {
        this.state.me.videoTrack.stop();
      } catch (e) {
        console.error('error stopping me video');
      }
      clearInterval(this.activityTimer);
      socketEvents.off('OFFERER_ICECANDIDATE', this.offererICECandidateListener);
      socketEvents.off('PROVIDER_HANGUP', this.handleProviderHangup);
      socketEvents.removeAllListeners('PROVIDER_HANGUP');
      socketEvents.removeAllListeners('RTC_OFFER');
      socketEvents.removeAllListeners('RTC_OFFER_RESTART');
      const videoElement1 = this.docVideo.current;
      const videoElement2 = this.patientVideo.current;
      this.state.stream.getTracks().forEach(t => t.stop());
      videoElement1.srcObject.getTracks().forEach(t => t.stop());
      videoElement2.srcObject.getTracks().forEach(t => t.stop());
      videoElement1.srcObject = null;
      videoElement2.srcObject = null;
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  offererICECandidateListener = (data) => {
    const { me } = this.state;
    try {
      if (me.setDescription) {
        me.peer.addIceCandidate(data.candidate).catch((ce) => {
          console.error('error adding ice candidate direct from offerer', ce);
        });
      } else {
        this.setState(prevState => ({
          me: {
            ...prevState.me,
            candidates: prevState.me.candidates.concat(data.candidate),
          },
        }));
      }
    } catch (err) {
      console.error('error adding ice candidate from offerer', err);
    }
  }

  startCamera = async () => {
    const { me, micOn } = this.state;
    const { appointmentId, clinicId, userId, user } = this.props;

    // prompt for access
    try {
      const stream = await startVideo(me, null, micOn);

      this.setState({ cameraState: 'started', stream, me });
      this.props.updateAppointment(clinicId, userId, appointmentId, user.token, 'WAITING');
      const patientVideo = this.patientVideo.current; // document.createElement('video');

      patientVideo.addEventListener('loadeddata', () => {
      });

      patientVideo.srcObject = me.stream;

      try {
        if (this.state.md.os() === 'iOS' && this.state.md.mobile()) {
          console.info('not manually playing video because', this.state.md.os(), this.state.md.mobile());
        } else {
          await patientVideo.play();
        }
      } catch (err) {
        console.error('error starting video', err);
      }

      me.events.on('peer_connected', async () => {
        this.setState({ peerConnected: true, connecting: false });
      });
      socketEvents.on('PROVIDER_SCREENSHARE', this.providerScreenShare);

      socketEvents.on('OFFERER_ICECANDIDATE', this.offererICECandidateListener);
      socketEvents.on('RTC_OFFER', async (data) => {
        await createPeer(data, me);
        me.setDescription = true;
        me.peer.setRemoteDescription(data.offer);
        const answer = await me.peer.createAnswer(config.sdpConstraints);

        me.peer.setLocalDescription(answer);
        messageClinic(clinicId, { type: 'RTC_ANSWER', answer });

        me.peer.onicecandidate = (e) => {
          if (e.candidate == null) {
            // resolve(me.peer.localDescription);
          } else {
            messageClinic(clinicId, { type: 'ANSWERER_ICECANDIDATE', candidate: e.candidate });
          }
        };
        if (me.candidates.length) {
          me.candidates.forEach((can) => {
            me.peer.addIceCandidate(can).catch((ce) => {
              console.error('error adding ice candidate after sending answer', ce);
            });
          });
        }
      });
      socketEvents.on('RTC_OFFER_RESTART', async (data) => {
        if (!me.peer) await createPeer(data, me);
        me.peer.setRemoteDescription(data.offer);

        const answer = await me.peer.createAnswer(config.sdpConstraints);
        me.peer.setLocalDescription(answer);

        messageClinic(clinicId, { type: 'RTC_ANSWER_RESTART', answer });
      });

      me.events.on('gotStream', this.addStream);
      me.events.on('gotTrack', this.addStream);
    } catch (err) {
      this.setState({ cameraError: err });
    }
  }

  addStream = debounce(async (stream) => {
    const { me: { audioTrack, videoTrack } } = this.state;

    try {
      // hide the patient video and then readd the class
      // this deals with a safari bug where adding/removing
      // the splash screen element caused the video to drop below it
      // on the page
      this.setState({ patientVideoClass: 'hidden', providerReady: true, providerHangup: false });
      const vid = this.docVideo.current;
      // The next 4 lines solve some performance goblins
      // that arise when you continually start/stop video from
      // the provider-app side
      if (vid) {
        if (vid.srcObject) vid.srcObject.getTracks().forEach(t => t.stop());
        await vid.pause();
        vid.srcObject = null;
        setTimeout(async () => {
          vid.srcObject = stream;
          try {
            await vid.play();
            this.setState({ patientVideoClass: 'patientVideo' });
          } catch (err) {
            console.error('error starting video', err);
            // workaround for edge browser, most of the time wants a user action to start stream
            // mic would be broadcasting now since were connected, but creepy for user who cant see yet
            audioTrack.enabled = false;
            videoTrack.enabled = false;
            this.setState({ askVideo: true, videoOn: false, micOn: false, delayedStream: stream });
          }
        }, 100);
      } else {
        console.log('vid element not ready', this.docVideo);
      }
    } catch (exp) {
      this.setState({ patientVideoClass: 'patientVideo' });
      console.error('error adding stream', exp);
    }
  }, 300, { maxWait: 500, trailing: true })

  handleComplete = () => {
    this.endCall();
    this.forwardWithQuery();
  }

  patientIsNotTyping = () => {
    messageClinic(this.props.clinicId, { type: 'PATIENT-IS-NOT-TYPING' });
  };

  handleChange = (e) => {
    const { value } = e.target;
    const { clinicId } = this.props;

    this.setState({ message: value });
    if (value.length > 0) {
      messageClinic(clinicId, { type: 'PATIENT-IS-TYPING' });
      clearTimeout(this.timer.current);
      this.timer.current = setTimeout(() => this.patientIsNotTyping(), 2000);
    } else {
      this.patientIsNotTyping();
    }
  };

  handleSendMessage = async () => {
    const { message, messageHx } = this.state;
    const { userId, clinicId, appointmentId } = this.props;
    if (message.length > 0) {
      messageClinic(clinicId, { type: 'PATIENT-SMS', message, appointment_id: appointmentId });
      this.patientIsNotTyping();
      this.setState({ messageHx: [...messageHx, { from: userId, message }], message: '' });
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  handleSubmitOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSendMessage();
    }
  }

  handleViewChat = () => {
    const { messageHx } = this.state;
    const { userId, appointmentId } = this.props;

    messageHx.find((msg) => {
      return !msg.patient_viewed && apiFetch(`/users/${userId}/appointments/${appointmentId}/messages`, { method: 'PUT', body: { patient_viewed: true } });
    });
    this.setState({ viewedAll: true, showChat: true });
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
  }

  handleCloseChat = () => {
    this.setState({ showChat: false });
  }

  providerScreenShare = (data) => {
    this.setState({ providerSharingScreen: data.providerSharingScreen });
  }

  toggleMic = () => {
    const { micOn, me: { audioTrack } } = this.state;
    audioTrack.enabled = !audioTrack.enabled;
    return this.setState({ micOn: !micOn });
  }

  toggleCamera = () => {
    const { videoOn, me: { videoTrack } } = this.state;
    videoTrack.enabled = !videoTrack.enabled;
    return this.setState({ videoOn: !videoOn });
  }

  toggleCameraMode = async () => {
    const {
      me,
      stream,
      cameraMode,
      micOn,
    } = this.state;
    const { clinicId } = this.props;
    const patientVideo = this.patientVideo.current;
    const newCameraMode = cameraMode === 'user' ? 'environment' : 'user';

    await stream.getTracks().forEach(t => t.stop());
    const newStream = cameraMode === 'user' ? await startRearVideo(me, 'chat', micOn) : await startVideo(me, null, micOn);

    patientVideo.srcObject = me.stream;
    messageClinic(clinicId, { type: 'PATIENT_CAMERA_MODE', cameraMode: newCameraMode });
    this.setState({
      videoOn: true,
      stream: newStream,
      cameraMode: newCameraMode,
    });

    return this.swapPatientVideoTrack(newStream);
  }

  swapPatientVideoTrack = async (stream) => {
    if (this.state.me.peer) {
      const videoTrack = stream.getVideoTracks()[0];
      let sender = this.state.me.peer.getSenders().find((sender) => {
        return sender.track.kind === videoTrack.kind;
      });
      sender.replaceTrack(videoTrack);

      const audioTrack = stream.getAudioTracks()[0];
      sender = this.state.me.peer.getSenders().find((sender) => {
        return sender.track.kind === audioTrack.kind;
      });
      sender.replaceTrack(audioTrack);
    }
    return stream;
  }

  handleStartVideo = async () => {
    const { me: { audioTrack, videoTrack }, delayedStream } = this.state;

    const vid = this.docVideo.current;
    await vid.pause();
    vid.srcObject = delayedStream;
    await vid.play();
    audioTrack.enabled = true;
    videoTrack.enabled = true;
    this.setState({ micOn: true, videoOn: true, askVideo: false, patientVideoClass: 'patientVideo' });
  }

  startPreparationVideo = async () => {
    const { cameraMode } = this.state;
    const { clinicId } = this.props;

    if (cameraMode !== 'user') await this.toggleCameraMode();

    try {
      addCallbacksToWebWorker({
        vrOnsigns: this.vrOnsigns,
        vrOnprocessing: this.vrOnprocessing,
        vrOnend: this.vrOnend,
        vrOntimeLeft: this.vrOntimeLeft,
      });
      await loadDetector();

      await preparationVideo(this.patientVideo.current, this.patientCanvasVisible.current);

      this.setState({
        patientCanvasVisibleHeight: this.patientVideo.current.videoHeight,
        patientCanvasVisibleWidth: this.patientVideo.current.videoWidth,
        inIvcMode: true,
      });

      messageClinic(clinicId, { type: VITAL_CORE_MSGS_OUT.pendingPatientApproval });

    } catch (err) {
      // this.setState({ cameraError: err, errorDialogOpen: true });
    }
  };

  vrOntimeLeft = (timeLeft, percentLeft) => {
    this.setState({ timeLeft, percentLeft });
  };

  vrOnsigns = (data) => {
    const { HR: curHR, ivcSignsMsgs } = this.state;
    const { signs, cachedFrameCount, processedFrameCount } = data;
    ivcSignsMsgs.push(pick(signs, ['timestamp', 'HR', 'snrHR', 'dataStatus']));
    const {
      signalPercent,
      hrSignalPercent,
      dataStatus,
    } = signs;
    let { HR } = signs;

    if (curHR > 0 && HR < 1) HR = curHR;

    const processedDataStatus = vcProcessDataStatus(dataStatus);

    if (processedDataStatus.action === 'warning') {
      processedDataStatus.data.forEach((dataStatusMessage) => { this.addWarning(dataStatusMessage); });
    } else if (processedDataStatus.action === 'restart' || processedDataStatus.action === 'reload') {
      const {
        ivcCriticalErrorCount,
        ivcSessionCount,
        ivcVitalsMeasurements,
      } = this.state;

      const stateUpdate = {
        ivcCriticalErrorCount: ivcCriticalErrorCount + 1,
        ivcSessionCount: ivcSessionCount + 1,
        ivcVitalsMeasurements: [signs, ...ivcVitalsMeasurements],
        ivcState: IVC_STATE.showResults,
      };

      this.setState(stateUpdate);
    }

    this.setState({
      HR,
      signalPercent,
      hrSignalPercent,
      cachedFrameCount,
      processedFrameCount,
    });
  };

  vrOnend = (lastSigns) => {
    const { clinicId } = this.props;
    const {
      ivcCriticalErrorCount,
      ivcSessionCount,
      ivcVitalsMeasurements,
    } = this.state;

    const stateUpdate = {
      ivcSessionCount: ivcSessionCount + 1,
      ivcVitalsMeasurements: [lastSigns, ...ivcVitalsMeasurements],
      ivcState: IVC_STATE.showResults,
      coreWarnings: [],
      percentLeft: 1,
    };

    const processedDataStatus = vcProcessDataStatus(lastSigns.dataStatus, true);

    if (processedDataStatus.action === 'restart') {
      stateUpdate.ivcShouldRestart = true;
      stateUpdate.ivcCriticalErrorCount = ivcCriticalErrorCount + 1;
      messageClinic(clinicId, { type: VITAL_CORE_MSGS_OUT.error });
    } else {
      messageClinic(clinicId, { type: VITAL_CORE_MSGS_OUT.measured });
    }

    killWebWorker();
    resetVitalsRunnerOnUnmount();
    clearTimeout(this.processingTimer);

    this.setState(stateUpdate);
  };

  startMeasuringVitals = () => {
    const { clinicId } = this.props;

    this.setState({
      coreWarnings: [],
      ivcDisplayResults: false,
      ivcHeaderText: '',
      ivcInstructionsText: '',
      ivcMessageText: '',
      ivcActionType: '',
      ivcVitalsOutOfRange: false,
      ivcState: IVC_STATE.measuring,
    }); 


    try {
      const patientVideo = this.patientVideo.current;
      const canvas = this.patientCanvas.current;
      const patientCanvasVisible = this.patientCanvasVisible.current;

      this.moduleDownloadingTimer = setTimeout(() => {
        this.setState({
          ivcTimeoutData: {
            header: 'Unable to download the IVC App.',
            messageOne: <>Please try again later when you have access to the internet and tap <strong>RETRY.</strong></>,
            pageTitle: 'No Internet',
            icon: <NoInternet />,
          },
          ivcState: IVC_STATE.timeout,
        });

        messageClinic(clinicId, { type: VITAL_CORE_MSGS_OUT.error });
      }, processingTimeoutLength);

      if (!this.noSleep) {
        this.noSleep = new NoSleep();
        this.noSleep.enable();
      }

      const startWhenWorkerIsReady = async () => {
        const workerIsReady = isWebWorkerReady();
        if (workerIsReady) {
          this.setState({ workerReady: true });
          clearTimeout(this.moduleDownloadingTimer);
          await startVitalsRunner(patientVideo, canvas, patientCanvasVisible, {
            vrOnprocessing: () => {},
            vrOntimeLeft: this.vrOntimeLeft,
            vrOnend: this.vrOnend,
            vrGetCoreWarnings: () => {},
            vrOnVitalCoreVersion: () => {},
            vrGetAuthToken: this.vrGetAuthToken,
            vrLicenseError: () => {},
            vrOnAuthorizeVitals: this.vrOnAuthorizeVitals,
          });

          startSessionJS(true, true, true, true);
        } else {
          window.requestAnimationFrame(startWhenWorkerIsReady);
        }
      };

      window.requestAnimationFrame(startWhenWorkerIsReady);

      messageClinic(clinicId, { type: VITAL_CORE_MSGS_OUT.initializingCoreModule });
    } catch (err) {
      this.setState({ cameraError: err, errorDialogOpen: true });
    }
  }

  vrGetAuthToken = async (getRunToken) => {
    const { user } = this.props;
    const runToken = JSON.parse(await getRunToken());
    const options = makeShadowOptions(runToken, user.token, 'POST');
    return apiFetch(`/users/${user.id}/lite/vital_core/auth`, options);    
  };

  vrOnAuthorizeVitals = (authorizedVitals) => {
    const { clinicId } = this.props;

    messageClinic(clinicId, { type: VITAL_CORE_MSGS_OUT.authorizedVitals, authorizedVitals: authorizedVitals.vitals });
    this.setState({ authorizedVitals: authorizedVitals.vitals });
  };

  addWarning = (warningCode) => {
    const { coreWarnings } = this.state;

    const warningCodeMessage = vcDataStatusMessageMap[warningCode];

    if (!coreWarnings.includes(warningCodeMessage)) {
      this.setState(prevState => ({ coreWarnings: [...prevState.coreWarnings, warningCodeMessage] }));
      setGuideBoxColor('red');

      setTimeout(() => {
        this.setState((prevState) => {
          const filteredWarnings = prevState.coreWarnings.filter(curWarning => curWarning !== warningCodeMessage);

          if (!filteredWarnings.length) {
            setGuideBoxColor('green');
          }

          return { coreWarnings: filteredWarnings };
        });
      }, 3000);
    }
  };

  closeIvcMode = () => {
    this.setState({
      inIvcMode: false,
      ivcState: IVC_STATE.ready,
    });
  }

  submitVitals = () => {
    const { clinicId } = this.props;
    const { ivcVitalsMeasurements } = this.state;
    const {
      BP_DIA,
      BP_SYS,
      BR,
      HR,
      SPO2,
    } = ivcVitalsMeasurements[0];

    messageClinic(clinicId, {
      type: VITAL_CORE_MSGS_OUT.results,
      BP_DIA,
      BP_SYS,
      BR,
      HR,
      SPO2,
    });

    this.closeIvcMode();
  }

  render() {
    const { classes } = this.props;
    const {
      cameraError,
      providerHangup,
      showPoorConnectionMessage,
      showChat,
      message,
      messageHx,
      viewedAll,
      clinic_is_typing,
      micOn,
      videoOn,
      cameraMode,
      askVideo,
      patientCanvasVisibleHeight,
      patientCanvasVisibleWidth,
      inIvcMode,
      coreWarnings,
      ivcState,
      ivcCriticalErrorCount,
      ivcSessionCount,
      ivcVitalsMeasurements,
      ivcShouldRestart,
      ivcErrorCode,
      ivcTimeoutData,
      authorizedVitals,
    } = this.state;

    const videoContainerStyle = { display: '' };
    const providerVideoStyle = { display: this.state.providerReady ? '' : 'none' };
    providerVideoStyle.transform = 'scaleX(-1)';

    const patientCanvasVisibleStyle = {};

    if (this.state.providerSharingScreen) {
      videoContainerStyle.backgroundColor = 'rgb(240, 240, 240)';
      providerVideoStyle.objectFit = 'contain';
      providerVideoStyle.transform = 'scaleX(1)';
    }

    const language = this.props.user.primary_language || 'en';

    if (inIvcMode) {
      patientCanvasVisibleStyle.display = 'block';
    }

    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer} >
            <video id="video" ref={this.docVideo} style={providerVideoStyle} className={classes.providerVideo} playsInline autoPlay />
            <video
              id="patientVideo"
              ref={this.patientVideo}
              playsInline
              autoPlay
              muted
              className={`${classes[this.state.patientVideoClass]} ${cameraMode === 'user' ? classes.patientVideoUser : classes.patientVideoEnvironment}`}
            />
            <canvas id="patientCanvas" ref={this.patientCanvas} style={styles.patientCanvas} />
            <canvas
              id="patientCanvasVisible"
              ref={this.patientCanvasVisible}
              className={classes.patientCanvasVisible}
              height={patientCanvasVisibleHeight}
              width={patientCanvasVisibleWidth}
              style={patientCanvasVisibleStyle}
            />
            <HiddenContent hidden={!providerHangup}>
              <div className={classes.waitingView}>
                <ClinicLogo clinic_id={this.props.clinicId} />
                <Divider className={classes.divider} />
                <p className={classes.waitingInstructions}>{i18nTranslator('waiting', 'videoCallPro2')}</p>
              </div>
            </HiddenContent>
            {inIvcMode ? (
              <div className={classes.ivcHeader}>
                {coreWarnings.length ? (
                  <div className={classes.vitalCoreErrorContainer}>
                    {coreWarnings.map(vitalCoreError => (
                      <div className={classes.vitalCoreErrorRow} key={vitalCoreError}>
                        <div className={classes.exclamationWrapperSmall}>
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className={classes.vitalCoreErrorText}>{vitalCoreError}</div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
            {inIvcMode ? (
              <div className={classes.ivcContentContainer}>
                {ivcState === IVC_STATE.showResults ? (
                  <IvcTelemedicineResults
                    criticalErrorCount={ivcCriticalErrorCount}
                    sessionCount={ivcSessionCount}
                    vitalsMeasurements={ivcVitalsMeasurements}
                    shouldRestart={ivcShouldRestart}
                    errorCode={ivcErrorCode}
                    onRestart={() => {
                      this.setState({ inIvcMode: false, ivcState: IVC_STATE.ready, percentLeft: 1 });
                      this.startPreparationVideo();
                    }}
                    onSubmit={this.submitVitals}
                    authorizedVitals={authorizedVitals}
                  />
                ) : null}
                {ivcState === IVC_STATE.timeout ? (
                  <IvcTelemedicineTimeout
                    {...ivcTimeoutData}
                    onRetry={() => this.setState({ ivcState: IVC_STATE.ready })}
                  />
                ) : null}
                {ivcState === IVC_STATE.analyzing ? (
                  <div className={classes.processingSection}>
                    <div className={classes.circularProgressContainer}>
                      <div className={classes.circularProgressWrapper}>
                        <CircularProgress
                          value={Math.round((this.state.processedFrameCount / this.state.cachedFrameCount) * 100)}
                          variant="determinate"
                          size={100}
                        />
                      </div>
                      <div className={classes.circularProgressText}>
                        <div>
                          <span>{Math.round((this.state.processedFrameCount / this.state.cachedFrameCount) * 100)}</span>
                          <span className={classes.circularProgressTextPercent}>%</span>
                        </div>
                      </div>
                    </div>
                    <p><strong>Analyzing...</strong></p>
                    <p>Please sit tight while we analyze your video.</p>
                    <p>This should only take a minute or two.</p>
                  </div>
                ) : null}
                {ivcState === IVC_STATE.ready ? (
                  <div className={classes.ivcButtonWrapper}>
                    <button
                      className={classes.ivcButton}
                      onClick={this.startMeasuringVitals}
                      type="button"
                    >
                      START
                    </button>
                  </div>
                ) : null}
                <HiddenContent hidden={this.state.workerReady || !(ivcState === IVC_STATE.measuring)}>
                  <div className={classes.loadingModel}>
                    <span className={classes.linearProgressText}>Loading</span>
                    <LinearProgress
                      className={classes.linearProgress}
                      value={Math.round(this.state.workerPct * 100)}
                      variant="indeterminate"
                    />
                  </div>
                </HiddenContent>
                <HiddenContent hidden={!this.state.workerReady || !(ivcState === IVC_STATE.measuring)}>
                  <div className={classes.loadingModel}>
                    <span className={classes.linearProgressText}>Collecting</span>
                    <LinearProgress
                      className={classes.linearProgress}
                      value={Math.round((1 - this.state.percentLeft) * 100)}
                      variant="determinate"
                    />
                  </div>
                </HiddenContent>
              </div>
            ) : null}
          </div>
          <Snackbar
            open={cameraError.length > 0 && !showPoorConnectionMessage}
            onClose={() => this.setState({ cameraError: '' })}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>{cameraError}{i18nTranslator('cameraError', 'videoCallPro2')}: {cameraError.toString()}</MuiAlert>
          </Snackbar>
          <Snackbar
            open={showPoorConnectionMessage}
            onClose={() => this.setState({ showPoorConnectionMessage: false })}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>{i18nTranslator('poorConnection', 'videoCallPro2')}</MuiAlert>
          </Snackbar>
          <Snackbar
            open={(!cameraError && !showPoorConnectionMessage) && (this.state.patientVideoClass === 'providerVideo')}
            onClose={() => this.setState({ cameraError: '' })}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="info" style={{ fontSize: 16 }}>{i18nTranslator('alert', 'videoCallPro2')}</MuiAlert>
          </Snackbar>

          <HiddenContent hidden={!askVideo}>
            <Button
              onClick={this.handleStartVideo}
              className={classes.letInProviderButton}
              color="primary"
              variant="contained"
            >
              {i18nTranslator('providerReady', 'videoCallPro2')}
            </Button>
          </HiddenContent>

          <HiddenContent hidden={showChat || inIvcMode}>
            <div className={classes.bottomNavMenu}>
              <Fab
                aria-label="Switch Camera View"
                color="primary"
                onClick={this.toggleCameraMode}
                className={classes.bottomNavIcon}
              ><FlipCameraIosIcon />
              </Fab>
              {videoOn ? (
                <Fab
                  disabled={askVideo}
                  aria-label="Camera On"
                  color="primary"
                  onClick={this.toggleCamera}
                  className={classes.bottomNavIcon}
                ><VideocamIcon />
                </Fab>
              ) : (
                <Fab
                  disabled={askVideo}
                  aria-label="Camera Off"
                  color="default"
                  onClick={this.toggleCamera}
                  className={classes.bottomNavOffIcon}
                ><VideocamOffIcon />
                </Fab>
              )}
              {micOn ? (
                <Fab
                  disabled={askVideo}
                  aria-label="Mic On"
                  color="primary"
                  onClick={this.toggleMic}
                  className={classes.bottomNavIcon}
                ><MicIcon />
                </Fab>
              ) : (
                <Fab
                  disabled={askVideo}
                  aria-label="Mic Off"
                  color="default"
                  onClick={this.toggleMic}
                  className={classes.bottomNavOffIcon}
                ><MicOffIcon />
                </Fab>
              )}
              <Fab
                aria-label="Chat"
                color="default"
                onClick={this.handleViewChat}
                className={viewedAll ? classes.bottomNavIcon : classes.unreadMessages}
              >
                <Badge color="secondary" variant="dot" overlap="circular" invisible={viewedAll}>
                  <ChatBubbleIcon />
                </Badge>
              </Fab>
            </div>
          </HiddenContent>

          <HiddenContent hidden={!showChat || inIvcMode}>
            <div className={classes.chat}>
              <div className={classes.closeChat}>
                <ExpandMoreIcon style={{ fontSize: 35, justifySelf: 'flex-end' }} onClick={this.handleCloseChat} />
              </div>
              <div className={classes.messageSection}>
                <Box flexGrow={1} className={classes.messageDisplayBox}>
                  <div style={{ height: '85%' }}>
                    {messageHx.map((message) => {
                      const sender = message.from === this.props.userId ? 'Me' : 'Clinic';
                      return (
                        <div>
                          <Typography key={message.message} variant="body1" className={classes.message}><strong>{sender}:</strong> {message.message}</Typography>
                        </div>
                      );
                    })}
                  </div>
                  {clinic_is_typing && <Typography variant="body1"><em>{i18nTranslator('clinicIsTyping', 'videoCallPro2')}</em></Typography>}
                  <div ref={el => this.messagesEndRef.current = el} />
                </Box>
                <Box className={classes.messageBox}>
                  <Input
                    type="text"
                    placeholder={language === 'es' ? 'Toca aquí para entrar' : 'Tap to enter message'}
                    variant="filled"
                    disableUnderline
                    value={message}
                    onChange={this.handleChange}
                    className={classes.messageField}
                    onKeyPress={this.handleSubmitOnEnter}
                  />
                  <Button
                    onClick={this.handleSendMessage}
                  >
                    <SendIcon color="primary" style={{ fontSize: 40 }} />
                  </Button>
                </Box>
              </div>
            </div>
          </HiddenContent>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;
  const { id, clinic_id, hangupReload } = ownProps.location.query;

  return {
    clinicId: get(user, 'meta.clinic_id', clinic_id),
    userId: get(user, 'id'),
    appointmentId: get(user, 'meta.appointment_id', id),
    hangupReload,
    user,
  };
}

export default connect(mapStateToProps, { submitPRO, updateAppointment })(withStyles(styles)(VideoCallPro2));
