import { ivcPros, ivcRRPros, vtPros } from './vital-core';

const addBloomkidzFont = () => {
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

function buildTrackFromBundledEvents(events, notificationId, dob) {
  if (!events || !events.length) {
    throw new Error('Invalid Bundled Notification events!');
  }

  let addedBloomkidzFont = false;

  return events.reduce((all, e) => {
    let redirect = false;

    if (ivcPros.includes(e.event_type) || ivcRRPros.includes(e.event_type) || vtPros.includes(e.event_type)) {
      redirect = dob;
    }

    if (e.event_type.includes('BLOOMKIDZ') && !addedBloomkidzFont) {
      addBloomkidzFont();
      addedBloomkidzFont = true;
    }

    if (e.stepCompleted) {
      return all;
    }
    all.push({
      track: `notification-${e.event_type}`,
      query: {
        type: e.event_type,
        id: e.pro_submission_id || e.video_broadcast_id || e.voice_broadcast_id,
        notification_id: notificationId,
        redirect,
        railroad: true,
        audioSrc: e.audioSrc,
        videoSrc: e.videoSrc,
        video_broadcast_id: e.video_broadcast_id,
        voice_broadcast_id: e.voice_broadcast_id,
      },
    });

    return all;
  }, []);
}

export default buildTrackFromBundledEvents;
