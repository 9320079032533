import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';
import Page from './page';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import ProWrapper from '../lib/pro-wrapper';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    backgroundColor: colors.secondaryColor
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
};

class BodyPainPro1 extends Page {
  state = {
    currentlyInPain: null,
    painType: null,
  };

  handleHide = () => {
    this.setState({
      currentlyInPain: false,
    });
  }

  handleShow = () => {
    this.setState({
      currentlyInPain: true,
    });
  }

  handleNewPain = () => {
    this.setState({
      painType: 'newPain',
    });
  };

  handleChronicPain = () => {
    this.setState({
      painType: 'chronicPain',
    });
  };


  render() {
    const { id } = this.props.location.query;
    const { user } = this.props;
    const { currentlyInPain, painType } = this.state;

    const handleContinue = async () => {
      if (currentlyInPain) {
        this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: painType });
        this.forwardWithQuery(this.props.location.query);
      } else {
        this.props.submitPRO(
          { userId: user.id, id },
          {
            pro_type: 'BODY-PAIN',
            pro_data: { data: [false] },
          },
          user,
        );
        this.finishTrack();
      }
    };

    let disabled = true;

    if (currentlyInPain === false) {
      disabled = false;
    } else if (currentlyInPain && painType) {
      disabled = false;
    }

    return (
      <ProWrapper
        header="Body Pain"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={handleContinue}
        disabled={disabled}
        btnStyle={styles.nextBtn}
      >
        <div style={styles.titleGroup}>
          <p style={styles.text}>Are you currently experiencing body pain?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={currentlyInPain ? 'primary' : 'default'} onClick={() => this.setState({ currentlyInPain: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={currentlyInPain === false ? 'primary' : 'default'} onClick={() => this.setState({ currentlyInPain: false })}>No</Button>
          </div>
        </div>

        <div className={`${currentlyInPain ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>Is there a new pain or chronic pain (more than 6 months old?)</p>

          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={painType === 'newPain' ? 'primary' : 'default'} onClick={this.handleNewPain}>New</Button>
            <Button style={styles.button} variant="contained" color={painType === 'chronicPain' ? 'primary' : 'default'} onClick={this.handleChronicPain}>Chronic</Button>
          </div>
        </div>
      </ProWrapper>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

BodyPainPro1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(BodyPainPro1);
