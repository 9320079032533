import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { KeypadDate } from 'common-ui';
import moment from 'moment';
import MenuIcon from '@material-ui/icons/Menu';
import { CircularProgress, IconButton } from '@material-ui/core';

import Page from './page';
import { logEvent } from '../lib/amplitude';
import buildTrackFromBundledEvents from '../lib/build-track-from-bundled-events';
import {
  loginShadow,
  addRequestingClinicNumber,
  setNotificationAuthLogoutRoute,
} from '../state/user';
import { clearPros } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import LoadingContainer from '../components/loading-container';
import HiddenContent from '../components/hidden-content';
import ClinicLogo from '../components/clinic-logo';
import { apiFetch } from '../lib/fetch';
import config from '../config';
import { throttledReset } from '../initializers/activity';
import { ivcPros, ivcRRPros, vtPros } from '../lib/vital-core';
import { bloomkidzPros } from '../lib/bloomkidz';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    textAlign: 'center',
    color: colors.errorRed,
    margin: 15,
    fontWeight: 800,
    fontSize: fontSizing.small,
  },
};

const defaultMessage = 'This link may no longer be used. Please close the browser window.';

const addBloomkidzFont = () => {
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

class ShadowConfirmation extends Page {
  constructor(props) {
    super(props);

    this.state = {
      dob: '',
      error: '',
      clinic_id: null,
      clinicNumber: null,
      notificationType: false,
      notificationExpired: false,
      additionalDobAuthErrorMessage: '',
      showDobAuthErrorMessage: false,
      fetching: true,
    };

    this.props.setNotificationAuthLogoutRoute(window.location.pathname);

    this.activityTimer = setInterval(throttledReset, 10000);
  }

  async componentWillMount() {
    const clinic = await apiFetch(`/notifications_clinic/${this.props.params.notificationId}`);
    if (ivcPros.includes(clinic.notification_type)) {
      window.location.replace(`${config.IVC_URL}/notifications/${this.props.params.notificationId}`);
      return;
    }

    if (ivcRRPros.includes(clinic.notification_type)) {
      window.location.replace(`${config.IVC_RR_URL}/notifications/${this.props.params.notificationId}`);
      return;
    } 

    if (vtPros.includes(clinic.notification_type)) {
      window.location.replace(`${config.VT_URL}/notifications/${this.props.params.notificationId}`);
      return;
    }

    if (bloomkidzPros.includes(clinic.notification_type)) {
      addBloomkidzFont();
    }

    this.setState({ fetching: false });
    // login if dob, then set tracks and skip notificaiton page
    const { dob } = this.props.location.query;
    if (dob) {
      const login = await this.props.loginShadow(this.props.params.notificationId, dob);
      this.props.addRequestingClinicNumber(clinic.phone);
      const track = buildTrackFromBundledEvents(login.meta.bundled_events, this.props.params.notificationId);
      this.setRailroad(track);
      return;
    }
    this.setState({
      clinic_id: clinic.id,
      clinic_name: clinic.name,
      clinicNumber: clinic.phone,
      notificationType: clinic.notification_type,
      notificationExpired: clinic.notificationExpired || false,
    });
    document.title = clinic.name;
    const favicon = document.getElementById('favicon');
    const icon = await apiFetch(`/clinics/${this.state.clinic_id}/logo/100`);
    favicon.href = icon.url;
    favicon.onError = (e) => {
      e.target.onerror = null;
      favicon.href = 'img/favicon.ico';
    };
  }

  componentWillUnmount() {
    clearInterval(this.activityTimer);
  }

  handleChange = (dob) => {
    this.setState({ dob });
  }

  onSubmission = () => {
    this.setState({ error: '', additionalDobAuthErrorMessage: '', showDobAuthErrorMessage: false });
    this.props.clearPros();
    return this.props.loginShadow(this.props.params.notificationId, this.state.dob)
      .then((data) => {
        logEvent('DOB Confirmation Successful');
        this.props.addRequestingClinicNumber(this.state.clinicNumber);
        this.props.router.push({ pathname: '/notification-intro', state: { id: data.meta.pro_submission_id || data.meta.video_broadcast_id, type: data.notification_type, clinic_id: this.state.clinic_id } });
      })
      .catch((e) => {
        const { lang } = this.props.location.query;
        let message = get(e, 'content.message') || get(e, 'content.error') || defaultMessage;
        if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
          const additionalDobAuthErrorMessage = e.content.failedLoginAttemptMessage;
          return this.setState({ dob: '', additionalDobAuthErrorMessage, showDobAuthErrorMessage: true });
        }
        if (e.status === 403) {
          return this.props.router.push('/login?originalPath=/notifications&msg=notification');
        }
        if (e.status === 404) {
          message = 'THE DATE OF BIRTH ENTERED DOES NOT MATCH YOUR PATIENT RECORD.';
          if (lang === 'es') {
            message = 'Su fecha de nacimiento ingresada no coincide con sus registros.';
          }
        } else if (!navigator.onLine) {
          message = 'Lost Internet connection.  Please try again when you’re connected to the Internet.';
        } else if (e.status !== 410) {
          message = 'Something went wrong. Please try again later.';
        }
        this.setState({
          dob: '',
          error: message,
        });
      });
  }

  render() {
    if (this.state.fetching) {
      return (
        <div>
          <CircularProgress/>
        </div>
      );
    }
    const { notificationExpired } = this.state;
    const date = moment(this.state.dob);
    const { lang } = this.props.location.query;

    const details = {
      header: 'Confirm Patient Birth Date',
      title: 'Please confirm your date of birth',
      buttonLabel: 'Continue',
    };

    if (lang === 'es') {
      details.header = 'Acceso';
      details.title = 'Por favor confirme su fecha de nacimiento:';
      details.buttonLabel = 'Seguir';
    }

    const { header, title, buttonLabel } = details;

    const isIvcPro = this.state.notificationType && (ivcPros.includes(this.state.notificationType) || ivcRRPros.includes(this.state.notificationType));

    return (
      <div>
        <AppBar
          headerNode={header}
          noLeftNode={true}
          rightNode={this.state.notificationType === 'VITAL-CORE' || this.state.notificationType === 'VITAL-CORE-BENCH-HR' ? (
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : null}
        />
        {(!this.state.notificationType || isIvcPro) && (
          <div style={styles.logoContainer}>
            <LoadingContainer isFetching={true} />
          </div>
        )}
        {(this.state.notificationType && !isIvcPro) && (
          <Fragment>
            <ClinicLogo clinic_id={this.state.clinic_id} />
            {notificationExpired ? (
              <div>
                <p style={{ ...styles.error, fontWeight: 'normal' }}>The notification link has expired.</p>
                <p style={styles.error}><strong>Please check your messages for a more recent link or contact your healthcare provider.</strong></p>
                <p style={{ ...styles.error, fontWeight: 'normal' }}>You may now close this window</p>
              </div>
            ) : (
              <div style={styles.questionnaire}>
                <div style={styles.titleGroup}>
                  <div style={styles.title}>{title}</div>
                </div>
                <KeypadDate onChange={this.handleChange} value={this.state.dob} />
                <HiddenContent hidden={!this.state.error}>
                  <div style={styles.error}>
                    {this.state.error}
                  </div>
                </HiddenContent>
                <HiddenContent hidden={this.state.showDobAuthErrorMessage}>
                  <Continue
                    text={buttonLabel}
                    onClick={this.onSubmission}
                    disabled={!date.isValid()}
                    btnStyle={{ marginTop: '50px', alignSelf: 'center', maxWidth: '200px' }}
                  />
                </HiddenContent>
                <HiddenContent hidden={!this.state.showDobAuthErrorMessage}>
                  <div style={{ ...styles.error, fontWeight: 'normal' }}>
                    {`The date of birth has been entered incorrectly five times in a row.${this.state.additionalDobAuthErrorMessage ? ` ${this.state.additionalDobAuthErrorMessage}` : ''}`}
                  </div>
                  <p style={styles.error}>
                    <strong>Please contact your healthcare provider to verify your date of birth on file.</strong>
                  </p>
                  <div style={styles.error}>
                    <strong>You may now close this window.</strong>
                  </div>
                </HiddenContent>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { proForms } = state;
  return { proForms };
}

ShadowConfirmation.propTypes = {
  clearPros: PropTypes.func.isRequired,
  proForms: PropTypes.object.isRequired,
  loginShadow: PropTypes.func.isRequired,
  setNotificationAuthLogoutRoute: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  addRequestingClinicNumber,
  clearPros,
  loginShadow,
  setNotificationAuthLogoutRoute,
})(ShadowConfirmation);
