import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Continue from './continue';
import { submitPRO } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import { setErrorScreenData } from '../state/error-screens';
import {
  BloodPressure,
  HeartBeat,
  Lungs,
  Spo2,
} from '../lib/icons';

const primaryBlue = '#0c63aa';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  boldText: {
    fontWeight: 'bold',
  },
  continueButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorIconInnerWrapper: {
    width: 100,
  },
  errorIconRedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
    '& path': {
      fill: colors.errorRed,
    },
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  flexGrowOne: {
    flexGrow: 1,
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  list: {
    listStyleType: "''",
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 0,
  },
  marginBottom0: {
    marginBottom: 0,
  },
  page: {
    background: colors.questionBackground,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    height: '100%',
    paddingTop: 100,
  },
  text: {
    fontSize: fontSizing.body,
    padding: '0 20px',
    textAlign: 'center',
  },
  textCentered: {
    textAlign: 'center',
  },
  textNotCentered: {
    fontSize: fontSizing.body,
    padding: '0 20px',
  },
  vitalMeasurementContainer: {
    boxShadow: '0 0 8px lightgrey',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 5,
    alignItems: 'flex-end',
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    width: 150,
    marginLeft: 10,
  },
  vitalMeasurementValue: {
    fontSize: fontSizing.h1,
  },
  vitalMeasurementUnit: {
    fontSize: fontSizing.small,
    lineHeight: 1.6,
    marginLeft: 5,
  },
};

const VitalsValueDisplay = ({
  classes,
  color,
  icon: Icon,
  labelOne,
  labelTwo,
  units,
  value,
}) => (
  <Paper className={classes.vitalMeasurementContainer} style={{ color }}>
    <Paper className={classes.iconWrapper}>
      <Icon className={classes.icon} style={{ color }} />
    </Paper>
    <div className={classes.vitalMeasurementTitleContainer}>
      <div>{labelOne}</div>
      <div>{labelTwo}</div>
    </div>
    <div className={classes.vitalMeasurementValue}>{value}</div>
    <div className={classes.vitalMeasurementUnit}>{units}</div>
  </Paper>
);
VitalsValueDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  labelOne: PropTypes.string.isRequired,
  labelTwo: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
const VitalsValueDisplayStyled = withStyles(styles)(VitalsValueDisplay);

class IvcResults extends React.Component {
  render() {
    const {
      classes,
      vitalsMeasurements,
      onRestart,
      onSubmit,
      authorizedVitals,
    } = this.props;

    const [{
      BP_DIA,
      BP_SYS,
      BR,
      HR,
      SPO2,
    }] = vitalsMeasurements;

    const heartRate = HR > 0 ? HR : '--';
    const breathingRate = BR > 0 ? BR : '--';
    const bloodPressure = (BP_DIA > 0 && BP_SYS > 0) ? `${BP_SYS}/${BP_DIA}` : '--';
    const bloodOxygen = SPO2 > 0 ? SPO2 : '--';

    return (
      <>
        <div className={classes.page}>
          <div className={classes.flexGrowOne}>
            {authorizedVitals.HR ? (
              <VitalsValueDisplayStyled
                color={colors.healthyRed}
                icon={HeartBeat}
                labelOne="Pulse"
                labelTwo="Rate"
                units="bpm"
                value={heartRate}
              />
            ) : null}
            {authorizedVitals.BP ? (
              <VitalsValueDisplayStyled
                color={colors.healthyRed}
                icon={BloodPressure}
                labelOne="Blood"
                labelTwo="Pressure"
                units="mm/Hg"
                value={bloodPressure}
              />
            ) : null}
            {authorizedVitals.BR ? (
              <VitalsValueDisplayStyled
                color={primaryBlue}
                icon={Lungs}
                labelOne="Breathing"
                labelTwo="Rate"
                units="bpm"
                value={breathingRate}
              />
            ) : null}
            {authorizedVitals.SpO2 ? (
              <VitalsValueDisplayStyled
                color={primaryBlue}
                icon={Spo2}
                labelOne="Oxygen"
                labelTwo="Saturation"
                units="SpO2"
                value={bloodOxygen}
              />
            ) : null}
          </div>
          <div className={classes.continueButtonWrapper}>
            <Continue
              text="Submit Results"
              onClick={onSubmit}
              btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center' }}
            />
          </div>
          <div className={classes.continueButtonWrapper}>
            <Continue
              text="Retry"
              onClick={onRestart}
              btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center' }}
              variant="outlined"
            />
          </div>
        </div>
      </>
    );
  }
}

IvcResults.propTypes = {
  classes: PropTypes.object.isRequired,
  criticalErrorCount: PropTypes.number.isRequired,
  sessionCount: PropTypes.number.isRequired,
  shouldRestart: PropTypes.bool.isRequired,
  vitalsMeasurements: PropTypes.array.isRequired,
  errorCode: PropTypes.string.isRequired,
  onRestart: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  authorizedVitals: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { setErrorScreenData, submitPRO })(withStyles(styles)(IvcResults));
